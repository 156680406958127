<template>
  <div id="app">
    <header class="header">
      <div class="cont">
        <div class="logo"><img src="@/assets/logo.png" /></div>
        <div
          class="guide flex right"
          :class="active ? 'active' : ''"
          @click="active = false"
        >
          <router-link active-class="active" exact to="/">首页</router-link>
          <router-link v-for="(item, index) in common.top_menu" :key="index" active-class="active" v-bind:to="item.url">{{item.name}}</router-link>
        </div>
        <a href="javascript:;" class="toggle" @click="active = !active"
          ><span class="iconfont icon-menu"></span
        ></a>
      </div>
    </header>

    <div class="container"><router-view /></div>

    <footer class="footer" id="footer">
      <div class="long"></div>
      <div class="cont">
        <div class="flex">
          <ul class="site flex">
            <li v-for="(item,index) in common.top_menu" :key="index">
              <h6><router-link v-bind:to="item.url">{{item.name}}</router-link></h6>
              <router-link v-for="(item1, index1) in item.ztype" :key="index1" v-bind:to="item1.url">{{item1.name}}<br /></router-link>
            </li>
          </ul>
          <div class="flex tel">
            <div class="tel-info">
              全国统一热线
              <p>{{common.web_config.tel}}</p>
            </div>
            <div><img v-bind:src="common.web_config.ew" class="tel-code" alt="" /></div>
          </div>
        </div>
        <div class="copy" v-html="common.web_config.info">
          {{common.web_config.info}}
        </div>
      </div>

      <div class="long"></div>
    </footer>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "App",
  data() {
    return {
		common: [],
      active: false,
    };
  },
  mounted() {
this.$http.post('common.php',Qs.stringify({data_type:'common', type_id:this.$route.query.type_id}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
})
  },
  methods: {},
  watch: {
    //使用watch 监听$router的变化
    $route() {
      // window.scrollTo(0, 0);
      document.getElementById("app").scrollIntoView();
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: "微软雅黑", "microsoft yahei", "", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  padding-top: 10rem;
  color: #222222;
}
.flex {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.flex.column {
  -webkit-box-orient: vertical;
  flex-direction: column;
}
.wrap {
  -webkit-box-line: multiple;
  flex-wrap: wrap;
}
.header {
  height: 10rem;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
}
.toggle {
  display: none;
}
.cont {
  width: 152rem;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.logo {
  position: absolute;
  padding: 0 2.8rem;
  height: 11.8rem;
  line-height: 11.8rem;
  background: #900000;
  top: 0;
  left: 0;
  img {
    max-height: 5rem;
  }
}
.flex.right {
  -webkit-box-pack: right;
  justify-content: flex-end;
}
.flex.center {
  -webkit-box-pack: center;
  justify-content: center;
}
.flex.middle {
  -webkit-box-align: center;
  align-items: center;
}
.guide a {
  margin-left: 5rem;
  line-height: 10rem;
  font-size: 1.8rem;
  position: relative;
}
.guide a.active {
  color: #900000;
}
.guide a:after {
  width: 0%;
  height: 3px;
  background: #900000;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.4s ease;
}
.guide a.active:after {
  width: 100%;
}
.footer {
  background: #900000;
  color: #fff;
}
.site {
  width: 65%;
}
.long {
  height: 8rem;
  clear: both;
}
.site h6 {
  font-size: 2rem;
  margin-bottom: 2.5rem;
}
.site a {
  line-height: 3.6rem;
}
.tel {
  white-space: nowrap;
  text-align: right;
}
.tel {
  img {
    width: 11.2rem;
  }
  .tel-info {
    margin-right: 2.8rem;
    p {
      font-size: 3rem;
      margin-top: 1rem;
    }
  }
}
.copy {
  text-align: center;
  padding-top: 2.8rem;
  border-top: 1px solid #b70001;
  margin-top: 5rem;
}
.bg {
  padding: 8rem 0;
  position: relative;
}
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home {
  text-align: center;
  margin-bottom: 3.5rem;
}
.home h3 {
  font-size: 4.8rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 1.8rem;
  color: #333;
}
.home p {
  font-size: 2rem;
  color: #666666;
}
.home.white h3,
.home.white p {
  color: #fff;
}
.cloumn {
  display: -webkit-box;
  display: flex;
  flex-direction: column;
}
.end {
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.base {
  margin-bottom: 6rem;
  justify-content: space-around;
}
.base li {
  font-size: 2rem;
  text-align: center;

  &.sp {
    border-left: 1px solid #cccccc;
  }
}
.base li:first-child {
  border-left: none;
}
.base li span {
  font-size: 7.2rem;
  color: #8f0001;
  font-weight: bold;
  line-height: 1;
  margin-right: 3px;
  small {
    font-weight: 400;
    margin-left: 4px;
    font-size: 2.4rem;
    vertical-align: top;
  }
}
.banner {
  height: 78.8rem;
  position: relative;
  .banner-img {
    width: 100%;
    height: 78.8rem;
    object-fit: cover;
  }
  .banner-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      max-height: 12rem;
      max-width: 30%;
    }
  }
}
.kind {
  text-align: center;
  margin-bottom: 4rem;
  a {
    padding: 0 3.5rem;
    line-height: 4rem;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    display: inline-block;
    &.active {
      background: #8f0001;
      color: #fff;
    }
  }
}
.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pagination {
  padding-top: 3rem;
  a {
    font-size: 2rem;
    width: 4.8rem;
    height: 4.8rem;
    text-align: center;
    color: #666;
    line-height: 4.8rem;
    border: 1px solid #cccccc;
    margin: 0 0.4rem;
    &:hover,
    &.active {
      background: #8f0001;
      color: #fff;
      border-color: #8f0001;
    }
  }
}
.coco {
  li {
    width: 35.5rem;
    height: 14rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 2rem;
    color: #fff;
    margin-bottom: 3rem;
    transition: all 0.3s;
    &:hover {
      background: #8f0001;
      border-color: #8f0001;
    }
    img {
      margin-right: 6rem;
      max-height: 6rem;
    }
  }
}
.prize {
  padding-bottom: 6rem !important;
  .pagination2 {
    .swiper-pagination-bullet {
      width: 1.5rem;
      height: 3px;
      margin: 0 2px;
      border-radius: 0;
      transition: all 0.5s;
      &.swiper-pagination-bullet-active {
        width: 5.8rem;
        height: 9px;
        background: #8f0001;
      }
    }
  }
}
.play {
  position: relative;
  padding-right: 78rem;
  li {
    width: 36.7rem;
    height: 29.4rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    &:last-child {
      width: 77.6rem;
      height: 59.3rem;
      position: absolute;
      top: 0;
      right: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 640px) {
  #app {
    padding-top: 7rem;
  }
  .bg {
    padding: 5rem 0;
  }
  .long {
    height: 5rem;
  }
  .flex {
    -webkit-box-lines: multiple;
    flex-wrap: wrap;
  }
  .cont {
    width: 98%;
    margin: 0 auto;
  }

  .header {
    height: 7rem;
    background: #fff;
  }
  .toggle {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 7rem;
    font-size: 6rem;
    height: 7rem;
    line-height: 7rem;
    text-align: center;
    z-index: 2;
  }
  .guide {
    position: fixed;
    right: -20rem;
    transition: all 0.5s;
    top: 7rem;
    width: 20rem;
    background: #fff;
    text-align: center;
    a {
      margin-left: 0;
      display: block;
      width: 100%;
      font-size: 2rem;
      line-height: 6rem;
      &.active {
        background: #8f0001;
        color: #fff;
      }
    }
    &.active {
      right: 0;
    }
  }
  .coco li {
    width: 48%;
    margin-bottom: 4%;
  }
  .site {
    display: none;
  }
  .tel {
    width: 100%;
    -webkit-box-orient: vertical;
    flex-direction: column;
    text-align: center;
    .tel-info {
      padding-right: 0;
      padding-bottom: 1rem;
    }
  }
  .base li span {
    font-size: 4rem;
    small {
      font-size: 1.2rem;
    }
  }
  .base li.sp {
    display: none;
  }
  .banner {
    height: 30rem;
    .banner-img {
      height: 30rem;
    }
  }
  .play {
    padding-right: 0;
    li {
      width: 49% !important;
      height: 20rem !important;
      &:last-child {
        position: relative;
      }
    }
  }
  .logo {
    height: 7rem;
    line-height: 7rem;
    img {
      max-height: 4rem;
    }
  }
  .pagination a {
    margin: 0;
  }
}
</style>
