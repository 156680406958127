<template>
  <div class="page-home">
    <transition name="fadeInUp">
      <div class="ad" v-show="now > 0">
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide class="swiper-slide" v-for="(item, index) in common.banner" :key="index">
            <img v-bind:src="item.img" style="width: 100%" alt="" />
          </swiper-slide>
        </swiper>
        <!-- 左右箭头 -->
        <div class="ad-nav flex">
          <b>{{ active }}</b>
          <div class="iconfont icon-left ad-nav1" slot="button-prev"></div>
          <div class="iconfont icon-you ad-nav2" slot="button-next"></div>
        </div>
      </div>
    </transition>
    <transition name="fadeInLeft">
      <div class="bg" v-show="now > 1">
        <div class="cont" v-html="common.company.info">
          {{common.company.info}}
        </div>

        <transition name="fadeInUp">
          <div class="cont" v-show="now > 2">
            <div class="home">
              <h3>产品推荐</h3>
              <p>帝彤 ---------- 开启你的新“视”界</p>
            </div>
            <div class="door">
              <swiper :options="swiperOption2">
                <swiper-slide
                  class="swiper-slide"
                  v-for="(item, index) in product"
                  :key="index"
                >
                  <img v-bind:src="item.img" style="width: 100%" alt="" />
                  <div class="door-info">
                    <p>{{ item.name }}</p>
                    <a href="#/door" class="btn">了解详情</a>
                  </div>
                </swiper-slide>
              </swiper>
              <!-- 左右箭头 -->
              <div class="iconfont icon-left door-nav1" slot="button-prev">
                <span>{{
                  (product[index == 0 ? product.length - 1 : index - 1] || {})
                    .name
                }}</span>
              </div>
              <div class="iconfont icon-you door-nav2" slot="button-next">
                <span>{{
                  (product[index == product.length - 1 ? 0 : index + 1] || {})
                    .name
                }}</span>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fadeInRight">
      <div class="flex wholes" v-show="now > 3">
        <div class="whole flex column">
          <img src="@/assets/word.png" style="width: 100%" alt="" />
          <ul class="flex column">
            <li class="flex center middle" @mouseenter="showIt = 0">
              护墙系列
            </li>
            <li class="flex center middle active">—&nbsp; 整装定制 &nbsp;—</li>
            <li class="flex center middle" @mouseenter="showIt = 1">
              柜体系列
            </li>
          </ul>
        </div>

        <div class="whole-img">
          <transition-group name="bounceIn">
            <img
              v-bind:src="common.door.img"
              style="width: 100%"
              v-if="showIt === 0"
              key="1"
            />
            <img v-bind:src="common.whole.img" style="width: 100%" v-else key="2" />
          </transition-group>
        </div>
      </div>
    </transition>
    <transition name="fadeInUp">
      <div class="bg" v-show="now > 4">
        <div class="home">
          <h3>2大智能化生产基地</h3>
          <p>10万余平米现代智能化工厂</p>
        </div>
        <div class="machine">
          <swiper :options="swiperOption3">
            <swiper-slide v-for="(item, index) in common.factory" :key="index">
              <div class="machine-img">
                <img v-bind:src="item.img" style="width: 100%" alt="" />
                <div class="machine-info">{{item.name}}</div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- 左右箭头 -->
          <div class="iconfont icon-left prev3" slot="button-prev"></div>
          <div class="iconfont icon-you next3" slot="button-next"></div>
        </div>
        <div class="long"></div>
        <transition name="bounceIn">
          <div class="cont" v-show="now > 5">
            <div class="home">
              <h3>荣誉证书</h3>
              <p>匠心永恒，不负光阴 ---------- 致匠心</p>
            </div>
            <swiper :options="swiperOption4" class="prize">
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in common.cert"
                :key="index"
              >
                <img v-bind:src="item.img" style="width: 100%" alt="" />
              </swiper-slide>
              <div
                class="swiper-pagination pagination2"
                slot="pagination"
              ></div>
            </swiper>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fadeInUp">
      <div class="bg" v-show="now > 6">
        <img src="@/assets/dark.jpg" class="bg-img" alt="" />
        <div class="cont">
          <div class="home white">
            <h3>工程案例</h3>
            <p>用心做好细节，以诚赢得信赖</p>
          </div>
          <div class="tables">
            <div class="thead flex">
              <div>项目名称</div>
              <div>项目地址</div>
            </div>
            <table class="table">
              <tbody>
                <tr v-for="(item, index) in common.case" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.place }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
    <transition name="bounceInRight">
      <div class="cai" v-show="now > 7" style="background: #f5f5f5">
        <swiper :options="swiperOption5">
          <swiper-slide class="swiper-slide" v-for="(item, index) in common.case" :key="index">
            <img v-bind:src="item.img" style="width: 100%" alt="" />
          </swiper-slide>
        </swiper>
        <div class="cai-nav flex center">
          <div class="iconfont icon-left cai-nav1" slot="button-prev"></div>
          <div class="flex center middle">
            <div class="cai-pagination flex wrap center"></div>
          </div>
          <div class="iconfont icon-you cai-nav2" slot="button-next"></div>
        </div>
      </div>
    </transition>

    <transition name="bounceInLeft">
      <div class="bg" v-show="now > 8">
        <div class="cont">
          <div v-html="common.team.info">{{common.team.info}}</div>
          <div class="long"></div>

          <transition name="fadeInRight">
            <div class="cont" v-show="now > 9">
              <div class="home">
                <h3>最新资讯</h3>
                <p>观察行业视觉，用我们专业的角度，讲出你们的心声;</p>
              </div>
              <ul class="news flex">
                <li v-for="(item, index) in common.news" :key="index">
                  <a v-bind:href="item.url" class="news-img"
                    ><img v-bind:src="item.img" alt=""
                  /></a>
                  <div class="news-info">
                    <div class="news-dt">
                      <a v-bind:href="item.url">{{item.title}}</a>
                    </div>
                    <div class="news-dd">
                      {{item.info}}
                    </div>
                    <div class="news-time">{{item.add_time}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="fadeInUp">
      <div class="bg" v-show="now > 10">
        <img src="@/assets/coco.jpg" class="bg-img" alt="" />
        <div class="cont" v-html="common.join.info">
          {{common.join.info}}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "home",
  title: "",
  props: {
    msg: String,
  },
  data() {
    const that = this;
    return {
      showIt: 0,
      now: 0,
      active: 1,
      pindex: 0,
      common: [],
      product: [],
      swiperOption: {
        loop: true,
        navigation: {
          nextEl: ".ad-nav1",
          prevEl: ".ad-nav2",
        },
        on: {
          slideChangeTransitionEnd: function () {
            that.active = this.realIndex + 1;
          },
        },
      },
      swiperOption2: {
        loop: true,
        navigation: {
          nextEl: ".door-nav1",
          prevEl: ".door-nav2",
        },
        effect: "flip",
        on: {
          slideChangeTransitionEnd: function () {
            that.pindex = this.realIndex;
          },
        },
      },
      swiperOption3: {
        loop: true,
        navigation: {
          nextEl: ".next3",
          prevEl: ".prev3",
        },
      },
      swiperOption4: {
        loop: true,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "2",
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 200,
          modifier: 3,
          slideShadows: true,
        },
        pagination: {
          el: ".pagination2",
        },
      },
      swiperOption5: {
        loop: true,
        slidesPerView: "4",
        navigation: {
          nextEl: ".cai-nav1",
          prevEl: ".cai-nav2",
        },
        pagination: {
          el: ".cai-pagination",
        },
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于320
          320: {
            slidesPerView: 2,
          },
          //当宽度大于等于480
          480: {
            slidesPerView: 2,
          },
          //当宽度大于等于640
          640: {
            slidesPerView: 4,
          },
        },
      },
      height: 0,
      item: 4,
    };
  },
  mounted() {
this.$http.post('index.php',Qs.stringify({data_type:'common'}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	this.product=response.data.product;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 16) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.page-home {
  .ad {
    position: relative;
    b {
      font-size: 2.4rem;
      color: #fff;
      line-height: 5rem;
      margin-right: 4rem;
    }
    .ad-nav {
      position: absolute;
      bottom: 6rem;
      right: 20rem;
      z-index: 2;
      div {
        width: 5rem;
        height: 5rem;
        line-height: 4.8rem;
        border: 1px solid #fff;
        color: #fff;
        font-size: 2.4rem;
        text-align: center;
        margin-left: 10px;
        transition: all 0.5s;
        cursor: pointer;
        &:hover {
          background: #8f0001;
          color: #fff;
          border-color: #8f0001;
        }
      }
    }
  }

  .plam-img {
    width: 94.5rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .plam-info {
    width: 52.4rem;
    font-size: 2rem;
    line-height: 3.6rem;
    color: #666666;
    text-indent: 2rem;
  }
  .btn {
    width: 21.6rem;
    line-height: 5.8rem;
    font-size: 2rem;
    color: #fff;
    background: #8f0001;
    display: inline-block;
    text-align: center;
    &:hover {
      background: #c30a0b;
    }
  }
  .wholes {
    overflow: hidden;
    .whole {
      width: 36rem;
      background: #8f0001;
      ul {
        flex: 1;
        li {
          flex: 1;
          cursor: pointer;
          font-size: 1.8rem;
          background: #fff;
          text-align: center;
          &.active {
            background: #8f0001;
            font-size: 2.4rem;
            color: #fff;
          }
        }
      }
    }
    .whole-img {
      flex: 1;
      height: 89rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .door {
    padding: 0 22rem;
    position: relative;
    .door-info {
      text-align: center;
      padding-top: 5rem;
      p {
        font-size: 3.6rem;
        line-height: 1;
        margin-bottom: 2rem;
      }
    }
    .door-nav1,
    .door-nav2 {
      cursor: pointer;
      width: 5rem;
      height: 5rem;
      z-index: 200;
      line-height: 5rem;
      border: 1px solid #666666;
      text-align: center;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -2.5rem;
      span {
        position: absolute;
        white-space: nowrap;
        top: 0;
        left: 6.6rem;
      }
      &.door-nav2 {
        left: auto;
        right: 0;
        span {
          left: auto;
          right: 6.6rem;
        }
      }
      &:hover {
        color: #c30a0b;
        border-color: #c30a0b;
      }
    }
  }

  .machine {
    width: 108rem;
    position: relative;
    margin: 0 auto;
    & > .swiper-container {
      overflow: visible;
      .machine-img {
        padding: 0 3.5rem;
        .machine-info {
          width: 2.6rem;
          font-size: 2rem;
          color: #666666;
          position: absolute;
          top: 0;
          left: 0.6rem;
        }
      }
    }
    .icon-left,
    .icon-you {
      width: 4.8rem;
      height: 5rem;
      line-height: 4.8rem;
      font-size: 2rem;
      text-align: center;
      border: 1px solid #333;
      position: absolute;
      bottom: 0;
      z-index: 200;
      cursor: pointer;
      left: -1.6rem;
      &.icon-you {
        right: -1.6rem;
        left: auto;
      }
    }
  }

  .tables {
    background: #fff;
    border-bottom: 5px solid #c30a0b;
    .thead {
      background: #8f0001;
      div {
        width: 50%;
        font-size: 2.2rem;
        line-height: 8rem;
        color: #fff;
        text-align: center;
      }
    }
    .table {
      margin: 2rem 3rem;
      box-sizing: border-box;
      width: calc(100% - 6rem);
      tbody {
        tr:nth-child(odd) {
          background: #f2f2f2;
        }
        tr:hover {
          transition: all 0.3s;
          background: #8f0001;
          color: #fff;
        }
        td {
          width: 50%;
          font-size: 1.8rem;
          line-height: 2rem;
          padding: 1.5rem 0;
          text-align: center;
        }
      }
    }
  }
  .cai-nav {
    padding: 2rem 0;
    & > .flex {
      background: #fff;
      width: 3rem;
      padding: 0 2rem;
      .cai-pagination {
        /deep/ .swiper-pagination-bullet {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background: #999;
          opacity: 1;
          margin: 0.2rem;
          &.swiper-pagination-bullet-active {
            background: #8f0001;
          }
        }
      }
    }

    .iconfont {
      width: 4.8rem;
      height: 4.8rem;
      line-height: 4.8rem;
      text-align: center;
      position: relative;
      font-size: 2rem;
      margin: 0 0.5rem;
      background: #fff;
      transition: all 0.3s;

      &:hover {
        background: #8f0001;
        color: #fff;
      }
      &:after {
        border: 1px solid #333;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
      }
    }
  }
  .news {
    li {
      width: 48.7rem;
      background: #f5f5f5;
      transition: all 0.5s;
      &:hover {
        background: #8f0001;
        color: #fff;
      }
      .news-img {
        img {
          width: 100%;
          height: 27.3rem;
          display: block;
          object-fit: cover;
        }
      }
      .news-info {
        padding: 2.5rem 4rem;

        .news-dt {
          font-size: 2.6rem;
          margin-bottom: 2rem;
        }
        .news-dd {
          font-size: 1.8rem;
          line-height: 2.9rem;
          opacity: 0.8;
        }
        .news-time {
          margin-top: 3.5rem;
          opacity: 0.5;
        }
      }
    }
  }

  .brand {
    padding-top: 4rem;
    text-align: center;
    p {
      font-size: 6rem;
      color: #fff;
      line-height: 1.2;
      margin-bottom: 3.5rem;
    }
  }

  @media screen and (max-width: 640px) {
    .ad .ad-nav {
      right: 5rem;
    }
    .plam {
      .plam-img {
        width: 100%;
      }
      .plam-info {
        padding-top: 1rem;
        width: 100%;
      }
    }
    .wholes {
      .whole {
        width: 20%;
        ul {
          li {
            font-size: 1.4rem;
            cursor: pointer;
            &.active {
              font-size: 1.4rem;
            }
          }
        }
      }
      .whole-img {
        height: 30rem;
      }
    }
    .door {
      padding: 0;
      .door-nav1,
      .door-nav2 {
        margin-top: 6.5rem;
      }
    }
    .machine {
      width: 90%;
    }
    .news li {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}
</style>
