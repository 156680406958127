import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import "@babel/polyfill";
// import "lib-flexible";
import "./App.css";
import "./assets/iconfont.css";
import "swiper/css/swiper.css";
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.use(VueRouter);
Vue.use(VueAwesomeSwiper);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
